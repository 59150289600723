// Entry point for the build script in your package.json
import "trix"
import "./trix_config"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import "./controllers"

// import 'flowbite'
// need the turbo dist file instead of the standard flowbite index file (in addition to upgrading to v1.6.4)
// https://github.com/themesberg/flowbite/issues/88
// https://flowbite.com/docs/getting-started/rails/#esbuild
import "flowbite/dist/flowbite.turbo"

import "./video_autoplay"
