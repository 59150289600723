import { Controller } from "@hotwired/stimulus";
import * as Leaflet from "leaflet";
window.L = Leaflet;
require("leaflet.markercluster");

export default class extends Controller {
  static targets = ["map", "latitude", "longitude"];
  static values = { url: String, markerCounter: Number };

  connect() {
    setTimeout(() => {
      this.observeFrameSrcChange();
    }, 500);
    this.generateUrl();

    this.initializeMap();
    if (this.urlValue) {
      this.initializeMarkers();
      this.clusters();
    }
  }

  initializeMap() {
    const defaultCenter = [32.201065, -99.972289];
    const center = (this.latitudeTarget.value && this.longitudeTarget.value) ?
      [this.latitudeTarget.value, this.longitudeTarget.value] : defaultCenter;

    this._map = L.map(this.mapTarget, {
      center: center,
      zoom: 6,
      minZoom: 4,
      maxZoom: 18,
    });
    L.tileLayer("https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=9d0b0eff52fb4f0791c65e24eb09cfbb").addTo(this._map);
  }

  initializeMarkers() {
    this.markers = [];
    this.fetchMarkers();
  }

  clusters() {
    this.cluster = L.markerClusterGroup({
      maxClusterRadius: 20,
      disableClusteringAtZoom: 14,
      removeOutsideVisibleBounds: true,
      polygonOptions: {
        color: 'transparent',
        opacity: 0,
        fillOpacity: 0
      }
    });
    return this.cluster;
  }

  fetchMarkers() {
    const _this = this;
    const fetch_url = _this.urlValue;

    fetch(fetch_url)
      .then(response => response.json())
      .then(data => {
        data.hunts.forEach(function (data) {
          let basePosition = L.latLng(data.hunt_lat, data.hunt_lon);
          let price = data.hunt_price;

          const offset = 0.005;
          let latOffset = (Math.random() - 0.5) * offset;
          let lonOffset = (Math.random() - 0.5) * offset;
          let adjustedPosition = L.latLng(basePosition.lat + latOffset, basePosition.lng + lonOffset);

          let customMarker = L.divIcon({
            className: "leaflet-data-marker",
            html: '<div class="px-2 py-1 rounded-lg bg-base-100 text-center font-bold w-fit text-sm shadow-md min-w-[48px]">' + (price !== null ? price : '$0') + '</div>',
            iconAnchor: [30, 0],
          });

          let marker = L.marker(adjustedPosition, {
            title: data.title,
            icon: customMarker
          });

          let popupContent = `
<div class="rounded-md shadow-md w-[280px]">
 <a href="${data.hunt_url}" class="block" target="_blank">
  <div class="rounded-t-md overflow-hidden relative w-full pb-[66%]">
   <img src="${data.hunt_preview_url}" alt="${data.hunt_title}" class="w-full h-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] object-cover z-[1]">
  </div>
 </a>
 <div class="p-4">
  <a href="${data.hunt_url}" class="block" target="_blank">
   <h2 class="font-medium text-xl sm:truncate sm:whitespace-nowrap mb-2 capitalize text-base-content">${data.hunt_title}</h2>
  </a>
  <div class="flex items-center justify-start text-sm space-x-1 mb-1">
   <div class="w-5 h-5 flex items-center justify-center shrink-0">
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="text-base-content/70">
     <path d="M5.01133 0H4.9886C2.23784 0 0 2.23709 0 4.9868C0 6.78923 0.820857 9.168 2.43976 12.0571C3.64001 14.1991 4.85708 15.8396 4.86927 15.8559C4.90315 15.9014 4.95655 15.9282 5.01312 15.9282C5.01473 15.9282 5.01638 15.9282 5.01799 15.9281C5.07636 15.9265 5.13024 15.8966 5.16262 15.848C5.17463 15.8298 6.38303 14.005 7.57512 11.773C9.18415 8.76049 10 6.47722 10 4.9868C9.99989 2.23705 7.76201 0 5.01133 0ZM7.31401 5.10866C7.31401 6.38467 6.27593 7.42271 4.99993 7.42271C3.72396 7.42271 2.68588 6.38463 2.68588 5.10866C2.68588 3.83269 3.72396 2.79462 4.99993 2.79462C6.27593 2.79462 7.31401 3.83269 7.31401 5.10866Z" fill="currentColor"></path>
    </svg>
   </div>
   <div class="max-w-[calc(100%-20px)] overflow-hidden">
    <div class="text-sm text-base-content/60 truncate whitespace-nowrap">${data.hunt_address}</div>
   </div>
  </div>
  <div class="mb-1 flex justify-between flex-nowrap items-center">
   <div class="max-w-[calc(100%-45px)] overflow-hidden">
    <a class="text-sm inline-flex" href="${data.outfitter_url}">
     <span class="text-primary font-medium">${data.outfitter_name}</span>
    </a>
   </div>
   ${data.outfitter_rating !== null ? `
   <div class="flex justify-end space-x-1 items-center">
    <svg width="14" height="13" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" class="text-primary">
     <path d="M7.04897 0.927078C7.34897 0.00607812 8.65197 0.00607812 8.95097 0.927078L10.021 4.21908C10.0863 4.41957 10.2134 4.59426 10.384 4.71818C10.5547 4.84211 10.7601 4.90892 10.971 4.90908H14.433C15.402 4.90908 15.804 6.14908 15.021 6.71908L12.221 8.75308C12.05 8.87711 11.9227 9.05209 11.8573 9.25293C11.7919 9.45377 11.7918 9.67017 11.857 9.87108L12.927 13.1631C13.227 14.0841 12.172 14.8511 11.387 14.2811L8.58697 12.2471C8.41618 12.1231 8.21053 12.0563 7.99947 12.0563C7.78842 12.0563 7.58277 12.1231 7.41197 12.2471L4.61197 14.2811C3.82797 14.8511 2.77397 14.0841 3.07297 13.1631L4.14297 9.87108C4.20815 9.67017 4.20803 9.45377 4.14264 9.25293C4.07725 9.05209 3.94994 8.87711 3.77897 8.75308L0.979974 6.72008C0.196974 6.15008 0.599974 4.91008 1.56797 4.91008H5.02897C5.24002 4.91013 5.44568 4.84342 5.6165 4.71948C5.78732 4.59554 5.91455 4.42073 5.97997 4.22008L7.04997 0.928078L7.04897 0.927078Z" fill="currentColor"></path>
    </svg>
    <p class="text-base-content text-sm">${data.outfitter_rating}</p>
   </div>` : ''}
  </div>
  <div class="min-h-[24px]">
   <span class="text-sm">Starting at</span>  <span class="font-bold">${data.hunt_price}</span>
  </div>
 </div>
</div>
        `;

          marker.bindPopup(popupContent);
          _this.markers.push(marker);
        });
        _this.cluster.addLayers(_this.markers);
        _this._map.addLayer(_this.cluster);
      })
      .catch(error => {
        console.error("Error fetching markers:", error);
      });
  }

  deleteMarkers() {
    if (this.cluster) {
      this._map.removeLayer(this.cluster);
      this.cluster.clearLayers();
    }
    this.markers = [];
  }

  generateUrl() {
    let url = document.getElementById("hunts_frame");
    let newUrl = url.src.replace("turbo_stream", "json?") + "&limit=20";
    newUrl = newUrl.replace("??", "?");
    this.urlValue = newUrl;
  }

  // Mutation observer for adding new hunts after page changes
  observeFrameSrcChange() {
    const turboFrame = document.getElementById('hunts_frame');
    const framePagination = document.getElementById("pagination");
    let counter = 1;

    const observer = new MutationObserver(() => {
      let url = framePagination.src.replace(2, 2 + counter);
      url = url.replace("turbo_stream", "json?");
      url = url.replace("??", "?");
      this.urlValue = url;
      if (this.markers.length < this.markerCounterValue) {
        this.fetchMarkers();
      }
      counter += 1;
    });

    observer.observe(turboFrame, {
      childList: true, // Observe changes to the child elements
      subtree: true // Observe changes in the Shadow DOM
    });
  }
}
